import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, ChildrenOutletContexts, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { fader, slider, stepper, transformer } from './animation';
import { LoadingService } from './shared/services/loader.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ // <-- add your animations here
    //fader,
    slider,
    // transformer,
    //stepper
  ]
})
export class AppComponent implements OnInit {
  constructor(
    private iconRegistry: MatIconRegistry,  private loadingService: LoadingService,
    sanitizer: DomSanitizer,

  ) {
    this.iconRegistry.addSvgIconSet(
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/sprite.svg')
    );

  }
  ngOnInit(): void {
   this.startLoader()
    console.log('by hanu11111111111111111111111111111111111111111111')
  }
  startLoader() {
    this.loadingService.start('info.loaderName', 'DataService');
  }

  stopLoader() {
    this.loadingService.stop('info.loaderName', 'DataService');
  }
}
